$max-width: 1280px;

// @responsive breakpoints
$small-screen: 464px; // 29em - 464px
$narrow-screen: 608px; // 38em - 608px
$tablet-screen: 768px; // 48em - 768px what else I call this?!?!?!
$medium-screen: 896px; // 56em - 896px
$standard-screen: 960px; // 60em - 960px or 60em
$wide-screen: 1188px; // 74.25em - 1188px = 1220px - (16px*2)
$super-wide-screen: 1408px; // 88em - 1408px = 1440px - (16px*2)

// @colour palettte  ==============================================

// theme colors
$grey: #808080;
$grey-light: #aeaeae;
$primary: #3edc3c;
$white: #fff;
$black: #222;

$primary-dark: darken($primary, 10);
$primary-light: lighten($primary, 10);

// @type settings    ==============================================

$light: 300;
$normal: 400;
$medium: 500;
$bold: 700;
$ultra-bold: 900;

// $base-font-size: 16px;
// $base-line-height: 26px;

@font-face {
  font-family: 'Druk Text Wide Web';
  src: url('../fonts/DrukTextWide-Medium-Web.eot');
  src: url('../fonts/DrukTextWide-Medium-Web.eot?#iefix') format('embedded-opentype'),
    url('../fonts/DrukTextWide-Medium-Web.woff2') format('woff2'),
    url('../fonts/DrukTextWide-Medium-Web.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Vaud";
  src: url("../fonts/Vaud.otf") format("opentype");
}
@font-face {
  font-family: "Vaud";
  font-weight: 300;
  src: url("../fonts/Vaud Light.otf") format("opentype");
}
@font-face {
  font-family: "Vaud";
  font-weight: bold;
  src: url("../fonts/Vaud Bold.otf") format("opentype");
}

$font-set-1: "Vaud", Helvetica, sans-serif;
$font-set-2: "Druk Text Wide Web", Helvetica, sans-serif;
