.block-de-quote-list {
  text-align: right;
}

div.block-search-button {
  padding-left: 3em;
  @include breakpoint($narrow-screen) {
    padding-left: 10em;
  }
}

.block-menu-btn {
  .contextual-links-wrapper {
    display: none !important;
  }
}

.block-search-button .block-content a,
.btn-main-menu,
.quote-list a {
  display: inline-block;
  color: $white;
  &:hover {
    color: $primary;
  }
}

.btn-main-menu {
  display: block;
  &:before {
    @include breakpoint($narrow-screen) {
      display: none;
    }
  }
}

.btn-main-menu > span {
  display: none;
  @include breakpoint($narrow-screen) {
    font-family: $font-set-2;
    font-size: 10px;
    text-transform: uppercase;
    vertical-align: middle;
    padding-bottom:.5em;
    display: inline-block;
    font-weight: bold;
  }
}

.block-search {
  input[type=submit] {
    @include button-hollow('white');
  }
}

.quote-amount {
  color: $primary;
}

.block-homepage-header {
  @include breakpoint($narrow-screen) {
    @include grid-span(8, 1);
  }
}

.front .block-logo {
  margin-bottom: 2em;
  text-align: center;
  @include breakpoint($narrow-screen) {
    margin-bottom: auto;
    @include grid-span(4, 9);
  }
  img {
    width: 100px;
    @include breakpoint($narrow-screen) {
      width: 150px;
    }
    @include breakpoint($standard-screen) {
      width: auto;
    }
  }
}

.block-down-arrow {
  padding-top: 2em;
  width: 100%;
  text-align: center;
  clear: both;
  @include breakpoint($narrow-screen) {
    padding-top: 4em;
  }
  .block-content a {
    color: white;
    text-decoration: none;
  }
}

.block-subpage-menus .block-content {
  @include clearfix;
  .menu {
    padding: 0;
    margin: 0;
  }
  li {
    display: block;
    width: 100%;
    padding-top: 1.5em;
    margin-top: 1em;
    margin-bottom: 2em;
    list-style-type: none;
    border-top: 1px solid $primary;
    @include breakpoint($narrow-screen) {
      display: inline-block;
      width: 48%;
    }
    &:nth-child(even) {
      float: right;
    }
    &:nth-child(odd) {
      float: left;
    }
  }
  h2 {
    text-transform: uppercase;
  }
  .btn-arrow:hover {
    color: $white;
  }
}

.node-type-department-category,
.node-type-expendable-categories {
  .block-subpage-menus .block-content .btn-arrow:hover {
    color: $black;
  }
}


.block-footer-menu {
  .menu {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;
    @include breakpoint($narrow-screen) {
      text-align: initial;
    }
    li {
      display: inline-block;
      &:last-child {
        &:after {
          content: '';
          padding: 0;
        }
      }
      &:after {
        content: '|';
        padding: .7em;
      }
    }
    a {
      text-decoration: none;
      color: white;
      font-weight: lighter;
      @include breakpoint(max-width $narrow-screen) {
        line-height: 1.6;
      }
      &:hover {
        color: $primary;
      }
    }
  }
}

.block-footer-logo {
  @include breakpoint(max-width $narrow-screen) {
    text-align: center;
    img {
      max-width: 220px;
    }
  }
  @include breakpoint($narrow-screen) {
    @include grid-span(6, 1);
  }
}

.block-footer-social-media {
  margin-bottom: 2em;
  text-align: center;
  @include breakpoint($narrow-screen) {
    margin-top: 2em;
    @include grid-span(6, 7);
  }
}

.footer-social-links {
  @include breakpoint($narrow-screen) {
    text-align: right;
  }
  a {
    display: inline-block;
    margin-left: 1em;
    font-size: 1.8em;
    color: $grey-light;
    @include breakpoint($standard-screen) {
      margin-left: 2.3em;
    }
    &:hover {
      color: $primary;
    }
    &:first-child {
      margin-left: 0;
    }
  }
}

.block-print-page {
  display: none;
  position: absolute;
  bottom: 0;
  right: 0;
  @include breakpoint($tablet-screen) {
    display: block;
  }
}

.print-page {
  display: inline-block;
  color: $white;
  text-decoration: underline;
  &:after {
    content: '';
    display: inline-block;
    margin-left: 5px;
    vertical-align: middle;
    width: 25px;
    height: 20px;
    background-image: url(/sites/all/themes/de_theme/dist/img/icon-print.png);
    background-repeat: no-repeat;
  }
  &:hover {
    color: $primary;
  }
}

.block-quote-checkout-note {
  font-size: 14px;
  @include breakpoint($tablet-screen) {
    width: 80%;
  }
}
