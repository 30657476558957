.view--contacts-and-locations--page {
  .view-header {
    margin-bottom: 3em;
    @include breakpoint($tablet-screen) {
      width: 70%;
    }
  }
  .view-content {
    @include add-grid(12);
    @include clearfix;
  }
  .views-row {
    position: relative;
    padding: 2em 2em 5em;
    margin-bottom: 1.69492%;
    min-height: 405px;
    border: 1px solid $white;
    @include breakpoint($small-screen) {
      @include float-span(6);
    }
    @include breakpoint($tablet-screen) {
      @include float-span(4);
    }
    &:before {
      left: 1%;
      bottom: 1%;
      width: 98%;
      height: 50px;
    }
  }
  .views-row-even {
    @include stripes('white');
    @include breakpoint($small-screen) {
      @include float-span(6, 'last');
    }
    @include breakpoint($tablet-screen) {
      @include float-span(4);
    }
  }
  .views-row-3, .views-row-6 {
    @include breakpoint($tablet-screen) {
      @include float-span(4, 'last');
    }
  }
  .views-row-odd {
    border-color: $primary;
    @include stripes('green');
    .views-field-title a {
      color: $white;
    }
  }
  .views-field-title {
    @extend h2;
    text-transform: uppercase;
  }
  .views-field-view-node,
  .views-field-title {
    a:hover {
      color: $white;
    }
  }
}

.node-contact-and-locations {
  .content {
    @include add-grid(12);
    @include clearfix;
  }
  .field-name-field-google-maps-address {
    display: none;
  }
  .field-name-field-address-and-hours {
    @include breakpoint($small-screen $tablet-screen - 1) {
      @include grid-span(6, 1);
    }
  }
  .field-name-field-image {
    @include breakpoint($small-screen $tablet-screen - 1) {
      text-align: right;
      @include grid-span(6, 7);
    }
    @include breakpoint($tablet-screen) {
      margin-top: 5em;
    }
    img {
      width: 100%;
      border: 1px solid $white;
      @include breakpoint($small-screen) {
        width: auto;
      }
    }
  }
  .group-location-container {
    @include breakpoint($small-screen) {
      @include clearfix;
    }
    @include breakpoint($tablet-screen) {
      @include grid-span(3, 9);
    }
  }
  .field-name-body {
    @include breakpoint($tablet-screen) {
      @include grid-span(7, 1);
    }
  }
}

#locations-map {
  width: 100%;
  height: 384px;
  margin-top: 2em;
  margin-bottom: 2em;
  border: 1px solid $white;

  @include breakpoint($tablet-screen) {
    margin-top: auto;
  }
  p:last-child {
    margin-bottom: 0;
  }
}

.views-field-field-address-and-hours a {
  display: inline-block;
}
