@font-face {
	font-family: 'Linearicons-Free';
	src:url('../fonts/linearicons/Linearicons-Free.eot?');
	src:url('../fonts/linearicons/Linearicons-Free.eot?#iefix') format('embedded-opentype'),
		url('../fonts/linearicons/Linearicons-Free.woff2?') format('woff2'),
		url('../fonts/linearicons/Linearicons-Free.woff?') format('woff'),
		url('../fonts/linearicons/Linearicons-Free.ttf?') format('truetype'),
		url('../fonts/linearicons/Linearicons-Free.svg?#Linearicons-Free') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="lnr-"], [class*=" lnr-"] {
	font-family: 'Linearicons-Free' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.lnr-home:after {
	content: "\e800";
}
.lnr-apartment:after {
	content: "\e801";
}
.lnr-pencil:after {
	content: "\e802";
}
.lnr-magic-wand:after {
	content: "\e803";
}
.lnr-drop:after {
	content: "\e804";
}
.lnr-lighter:after {
	content: "\e805";
}
.lnr-poop:after {
	content: "\e806";
}
.lnr-sun:after {
	content: "\e807";
}
.lnr-moon:after {
	content: "\e808";
}
.lnr-cloud:after {
	content: "\e809";
}
.lnr-cloud-upload:after {
	content: "\e80a";
}
.lnr-cloud-download:after {
	content: "\e80b";
}
.lnr-cloud-sync:after {
	content: "\e80c";
}
.lnr-cloud-check:after {
	content: "\e80d";
}
.lnr-database:after {
	content: "\e80e";
}
.lnr-lock:after {
	content: "\e80f";
}
.lnr-cog:after {
	content: "\e810";
}
.lnr-trash:after {
	content: "\e811";
}
.lnr-dice:after {
	content: "\e812";
}
.lnr-heart:after {
	content: "\e813";
}
.lnr-star:after {
	content: "\e814";
}
.lnr-star-half:after {
	content: "\e815";
}
.lnr-star-empty:after {
	content: "\e816";
}
.lnr-flag:after {
	content: "\e817";
}
.lnr-envelope:after {
	content: "\e818";
}
.lnr-paperclip:after {
	content: "\e819";
}
.lnr-inbox:after {
	content: "\e81a";
}
.lnr-eye:after {
	content: "\e81b";
}
.lnr-printer:after {
	content: "\e81c";
}
.lnr-file-empty:after {
	content: "\e81d";
}
.lnr-file-add:after {
	content: "\e81e";
}
.lnr-enter:after {
	content: "\e81f";
}
.lnr-exit:after {
	content: "\e820";
}
.lnr-graduation-hat:after {
	content: "\e821";
}
.lnr-license:after {
	content: "\e822";
}
.lnr-music-note:after {
	content: "\e823";
}
.lnr-film-play:after {
	content: "\e824";
}
.lnr-camera-video:after {
	content: "\e825";
}
.lnr-camera:after {
	content: "\e826";
}
.lnr-picture:after {
	content: "\e827";
}
.lnr-book:after {
	content: "\e828";
}
.lnr-bookmark:after {
	content: "\e829";
}
.lnr-user:after {
	content: "\e82a";
}
.lnr-users:after {
	content: "\e82b";
}
.lnr-shirt:after {
	content: "\e82c";
}
.lnr-store:after {
	content: "\e82d";
}
.lnr-cart:after {
	content: "\e82e";
}
.lnr-tag:after {
	content: "\e82f";
}
.lnr-phone-handset:after {
	content: "\e830";
}
.lnr-phone:after {
	content: "\e831";
}
.lnr-pushpin:after {
	content: "\e832";
}
.lnr-map-marker:after {
	content: "\e833";
}
.lnr-map:after {
	content: "\e834";
}
.lnr-location:after {
	content: "\e835";
}
.lnr-calendar-full:after {
	content: "\e836";
}
.lnr-keyboard:after {
	content: "\e837";
}
.lnr-spell-check:after {
	content: "\e838";
}
.lnr-screen:after {
	content: "\e839";
}
.lnr-smartphone:after {
	content: "\e83a";
}
.lnr-tablet:after {
	content: "\e83b";
}
.lnr-laptop:after {
	content: "\e83c";
}
.lnr-laptop-phone:after {
	content: "\e83d";
}
.lnr-power-switch:after {
	content: "\e83e";
}
.lnr-bubble:after {
	content: "\e83f";
}
.lnr-heart-pulse:after {
	content: "\e840";
}
.lnr-construction:after {
	content: "\e841";
}
.lnr-pie-chart:after {
	content: "\e842";
}
.lnr-chart-bars:after {
	content: "\e843";
}
.lnr-gift:after {
	content: "\e844";
}
.lnr-diamond:after {
	content: "\e845";
}
.lnr-linearicons:after {
	content: "\e846";
}
.lnr-dinner:after {
	content: "\e847";
}
.lnr-coffee-cup:after {
	content: "\e848";
}
.lnr-leaf:after {
	content: "\e849";
}
.lnr-paw:after {
	content: "\e84a";
}
.lnr-rocket:after {
	content: "\e84b";
}
.lnr-briefcase:after {
	content: "\e84c";
}
.lnr-bus:after {
	content: "\e84d";
}
.lnr-car:after {
	content: "\e84e";
}
.lnr-train:after {
	content: "\e84f";
}
.lnr-bicycle:after {
	content: "\e850";
}
.lnr-wheelchair:after {
	content: "\e851";
}
.lnr-select:after {
	content: "\e852";
}
.lnr-earth:after {
	content: "\e853";
}
.lnr-smile:after {
	content: "\e854";
}
.lnr-sad:after {
	content: "\e855";
}
.lnr-neutral:after {
	content: "\e856";
}
.lnr-mustache:after {
	content: "\e857";
}
.lnr-alarm:after {
	content: "\e858";
}
.lnr-bullhorn:after {
	content: "\e859";
}
.lnr-volume-high:after {
	content: "\e85a";
}
.lnr-volume-medium:after {
	content: "\e85b";
}
.lnr-volume-low:after {
	content: "\e85c";
}
.lnr-volume:after {
	content: "\e85d";
}
.lnr-mic:after {
	content: "\e85e";
}
.lnr-hourglass:after {
	content: "\e85f";
}
.lnr-undo:after {
	content: "\e860";
}
.lnr-redo:after {
	content: "\e861";
}
.lnr-sync:after {
	content: "\e862";
}
.lnr-history:after {
	content: "\e863";
}
.lnr-clock:after {
	content: "\e864";
}
.lnr-download:after {
	content: "\e865";
}
.lnr-upload:after {
	content: "\e866";
}
.lnr-enter-down:after {
	content: "\e867";
}
.lnr-exit-up:after {
	content: "\e868";
}
.lnr-bug:after {
	content: "\e869";
}
.lnr-code:after {
	content: "\e86a";
}
.lnr-link:after {
	content: "\e86b";
}
.lnr-unlink:after {
	content: "\e86c";
}
.lnr-thumbs-up:after {
	content: "\e86d";
}
.lnr-thumbs-down:after {
	content: "\e86e";
}
.lnr-magnifier:after {
	content: "\e86f";
}
.lnr-cross:after {
	content: "\e870";
}
.lnr-menu:after {
	content: "\e871";
}
.lnr-list:after {
	content: "\e872";
}
.lnr-chevron-up:after {
	content: "\e873";
}
.lnr-chevron-down:after {
	content: "\e874";
}
.lnr-chevron-left:after {
	content: "\e875";
}
.lnr-chevron-right:after {
	content: "\e876";
}
.lnr-arrow-up:after {
	content: "\e877";
}
.lnr-arrow-down:after {
	content: "\e878";
}
.lnr-arrow-left:after {
	content: "\e879";
}
.lnr-arrow-right:after {
	content: "\e87a";
}
.lnr-move:after {
	content: "\e87b";
}
.lnr-warning:after {
	content: "\e87c";
}
.lnr-question-circle:after {
	content: "\e87d";
}
.lnr-menu-circle:after {
	content: "\e87e";
}
.lnr-checkmark-circle:after {
	content: "\e87f";
}
.lnr-cross-circle:after {
	content: "\e880";
}
.lnr-plus-circle:after {
	content: "\e881";
}
.lnr-circle-minus:after {
	content: "\e882";
}
.lnr-arrow-up-circle:after {
	content: "\e883";
}
.lnr-arrow-down-circle:after {
	content: "\e884";
}
.lnr-arrow-left-circle:after {
	content: "\e885";
}
.lnr-arrow-right-circle:after {
	content: "\e886";
}
.lnr-chevron-up-circle:after {
	content: "\e887";
}
.lnr-chevron-down-circle:after {
	content: "\e888";
}
.lnr-chevron-left-circle:after {
	content: "\e889";
}
.lnr-chevron-right-circle:after {
	content: "\e88a";
}
.lnr-crop:after {
	content: "\e88b";
}
.lnr-frame-expand:after {
	content: "\e88c";
}
.lnr-frame-contract:after {
	content: "\e88d";
}
.lnr-layers:after {
	content: "\e88e";
}
.lnr-funnel:after {
	content: "\e88f";
}
.lnr-text-format:after {
	content: "\e890";
}
.lnr-text-format-remove:after {
	content: "\e891";
}
.lnr-text-size:after {
	content: "\e892";
}
.lnr-bold:after {
	content: "\e893";
}
.lnr-italic:after {
	content: "\e894";
}
.lnr-underline:after {
	content: "\e895";
}
.lnr-strikethrough:after {
	content: "\e896";
}
.lnr-highlight:after {
	content: "\e897";
}
.lnr-text-align-left:after {
	content: "\e898";
}
.lnr-text-align-center:after {
	content: "\e899";
}
.lnr-text-align-right:after {
	content: "\e89a";
}
.lnr-text-align-justify:after {
	content: "\e89b";
}
.lnr-line-spacing:after {
	content: "\e89c";
}
.lnr-indent-increase:after {
	content: "\e89d";
}
.lnr-indent-decrease:after {
	content: "\e89e";
}
.lnr-pilcrow:after {
	content: "\e89f";
}
.lnr-direction-ltr:after {
	content: "\e8a0";
}
.lnr-direction-rtl:after {
	content: "\e8a1";
}
.lnr-page-break:after {
	content: "\e8a2";
}
.lnr-sort-alpha-asc:after {
	content: "\e8a3";
}
.lnr-sort-amount-asc:after {
	content: "\e8a4";
}
.lnr-hand:after {
	content: "\e8a5";
}
.lnr-pointer-up:after {
	content: "\e8a6";
}
.lnr-pointer-right:after {
	content: "\e8a7";
}
.lnr-pointer-down:after {
	content: "\e8a8";
}
.lnr-pointer-left:after {
	content: "\e8a9";
}
