.leadership-team--wrapper {
  clear: both;
}
.leadership--teaser {
  @include clearfix;
  padding: 1.5em 0;
  border-top: 1px solid $primary;
  @include breakpoint($narrow-screen) {
    @include float-span(6, $gutter: 3);
    min-height: 250px;
  }
}
.field-name-field-leadership-member > .field-items > .field-item.odd > .leadership--teaser {
  @include breakpoint($narrow-screen) {
    @include float-span(6, 'last', $gutter: 3);
  }
}
.leadership--teaser-title {
  display: inline-block;
  h2 {
    margin-bottom: 0;
  }
}
.leadership--teaser-image {
  img {
    margin-left: 1em;
    float: right;
    border: 1px solid $grey;
  }
}
.leadership--teaser-link {
  margin-top: 3em;
  a {
    color: $primary;
    &:hover {
      color: $white;
    }
  }
}

.node-leadership-page .node-content-left,
.leadership-team--wrapper,
.field-name-field-partner-group-content {
  margin-bottom: 2em;
  @include breakpoint($tablet-screen) {
    margin-bottom: 3em;
  }
  @include breakpoint($standard-screen) {
    margin-bottom: 5em;
  }
}

.leadership-team--wrapper {
  @include add-grid(12);
}

.leadership-team--title {
  position: relative;
  padding-bottom: 4.5em;
  margin-bottom: 2em;
  margin-top: 2em;
  text-transform: none;
  @include breakpoint($tablet-screen) {
    margin-top: initial;
    padding-bottom: 3.5em;
  }
  @include breakpoint($standard-screen) {
    @include grid-span(3, 1);
  }
  @include stripes('green');
  &:before {
    left: 0;
    bottom: 0;
    width: 100%;
    height: 50px;
  }
  h3 {
    @extend h2;
  }
}

.leadership-team--rows {
  @include breakpoint($standard-screen) {
    @include grid-span(8, 5);
  }
}

.leadership-team--even {
  .leadership-team--title {
    color: $primary;
    @include stripes('white');
  }
  .leadership--teaser-title {
    color: $primary;
  }
}

.node-leadership-team.leadership--full {
  .content {
    @include add-grid(12);
  }
  .field-name-field-image {
    margin-bottom: 1em;
  }
  .field-name-field-image, .leadership--user-wrapper {
    display: table-cell;
    vertical-align: middle;
    @include breakpoint($tablet-screen) {
      display: block;
    }
  }
}

.leadership--user-wrapper {
  padding-left: 1em;
  @include breakpoint($tablet-screen) {
    padding-left: initial;
  }
}

.leadership--user {
  position: relative;
  padding-bottom: 4em;
  margin-bottom: 2em;
  @include breakpoint($tablet-screen) {
    @include grid-span(4, 9);
    padding-bottom: 6em;
  }
  @include stripes('green');
  &:before {
    left: 0;
    bottom: 0;
    width: 100%;
    height: 50px;
  }
  img {
    margin-bottom: 1em;
    border: 1px solid $grey;
    @include breakpoint($tablet-screen) {
      margin-bottom: auto;
    }
  }
}

.leadership--user-title {
  margin-bottom: 5px;
}

.leadership--description {
  @include breakpoint($tablet-screen) {
    @include grid-span(8, 1);
  }
}


.field-collection-item-field-partner-group {
  .field-name-field-subtitle {
    @extend .leadership-team--title;
    @extend h3;
    font-size: 1em;
    padding-bottom: 5em;
    @include breakpoint($standard-screen) {
      @include grid-span(3, 1);
    }
  }
  .field-name-field-group-images {
    @include breakpoint($standard-screen) {
      @include grid-span(8, 5);
    }
    > .field-items {
      > .field-item {
        display: inline-block;
        vertical-align: middle;
        width: 32%;
        margin-right: 2%;
        &:nth-child(3n) {
          margin-right: 0;
        }
      }
    }
  }
}


