.front, .not-front {
  color: white;
  background: $black;
}

.container {
  max-width: $max-width;
  margin: 0 auto;
}

.navigation {
  position: fixed;
  top: 0;
  left: 0;
  width: 300px;
  height: 100vh;
  background: $black;
}

.front .section-header {
  position: relative;
  overflow: hidden;
  //background: url('/sites/all/themes/de_theme/dist/img/banner-homepage.jpg') center center no-repeat;
  background-size: cover;
}

.section-sticky-header {
  position: sticky;
  top: 0;
  // position: relative;
  z-index: 20;
  border-bottom: 1px solid $grey;
  @include breakpoint(1025px) {
    .admin-menu & {
      top: 29px;
    }
  }
}

.region-sticky-header {
  position: relative;
  padding: 10px;
  z-index: 15;
  background: $black;
  @include breakpoint($tablet-screen) {
    padding: 10px 35px;
  }
  .block-content {
    font-family: $font-set-2;
    font-size: em(10px);
    letter-spacing: 2px;
  }
}

.footer, .section-content, .section-header, .footer-second {
  padding: 28px 13px;
  @include breakpoint($tablet-screen) {
    padding: 28px 35px;
  }
  @include breakpoint($standard-screen) {
    padding: 40px 35px;
  }
}

.front {
  .section-content, .section-header {
    @include breakpoint($tablet-screen) {
      padding: 40px 35px;
    }
  }
}

.not-front .section-content {
  border-top: 1px solid $grey;
  padding-top: 28px;
  padding-bottom: 28px;
  @include breakpoint($narrow-screen) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  @include breakpoint($tablet-screen) {
    padding-top: 75px;
    padding-bottom: 75px;
  }
}

.region-sticky-header .container {
  display: table;
  width: 100%;
  > div {
    display: table-cell;
    vertical-align: middle;
  }
  .block-content {
    text-transform: uppercase;
  }
}

.front .region-header {
  @include add-grid(12);
  .container {
    position: relative;
    padding: 2em 2em 5em;
    border: 1px solid $white;
    @include breakpoint($narrow-screen) {
      padding: 10em 2em 5em;
    }
    @include stripes('white');
    &:before {
      bottom: 5px;
      left: 5px;
      width: calc(100% - 10px);
      height: 50px;
      z-index: 1;
    }
  }
}

.not-front .section-header {
  .container {
    display: table;
    width: 100%;
  }
}

.section-header-left,
.section-header-right {
  position: relative;
  display: table-cell;
  vertical-align: middle;
}

.section-header-right {
  .block-logo {
    text-align: right;
    @include breakpoint($tablet-screen) {
      text-align: inherit;
    }
    img {
      max-width: 60px;
      @include breakpoint($tablet-screen) {
        max-width: 110px;
      }
    }
  }
}

.section-header-left {
  width: 70%;
}

.footer {
  padding-top: 2em;
  padding-bottom: 2em;
  border-top: 1px solid $grey;
  border-bottom: 1px solid $grey;
  @include add-grid(12);
}

.footer-second {
  padding-top: 1.5em;
  padding-bottom: 1.5em;
}
