.block-bean-homepage-blocks {
  position: relative;
  border: 1px solid white;
  vertical-align: top;
  margin-bottom: 1em;
  @include breakpoint($standard-screen) {
    display: inline-block;
  }
  @include breakpoint($wide-screen) {
    margin-bottom: 1.3em;
  }
  .field-name-field-body p {
    margin-bottom: 0;
  }
}

.homepage-blocks-row {
  @include add-grid(12);
}

.homepage-blocks-tablet-only {
  @include breakpoint($standard-screen) {
    display: none;
  }
}

.block-bean-educational-events,
.block-bean-quote-tool--2,
.block-bean-social-media--2 {
  @include breakpoint($narrow-screen $standard-screen) {
    min-height: 320px;
  }
}

.block-bean-quote-tool--2,
.block-bean-social-media--2 {
  @include breakpoint($narrow-screen) {
    @include float-span(4);
  }
}

.block-bean-educational-events {
  @include breakpoint($narrow-screen) {
    @include float-span(4, 'last');
  }
}

.block-bean-camera-rentals,
.block-bean-specialty-equipment {
  // @include breakpoint($standard-screen) {
  //   @include grid-span(8, 1);
  // }
}

.block-bean-quote-tool {
  @include breakpoint($standard-screen) {
    @include grid-span(8, 1);
  }
}

.block-bean-social-media {
  @include breakpoint($standard-screen) {
    @include grid-span(4, 9);
  }
}

.block-bean-educational-events--2{
  @include grid-span(4, 1);
}

// .block-bean-quote-tool,
// .block-bean-social-media {
//   height: 400px;
// }

.block-bean-news {
}

.block-bean-quote-tool--2,
.block-bean-social-media--2,
.block-bean-educational-events {
  border-color: $primary;
}

.block-bean-quote-tool,
.block-bean-social-media,
.block-bean-educational-events--2 {
  border-color: $primary;
  // @include breakpoint(max-width $standard-screen - 1) {
  //   display: none;
  // }
}

.block-bean-camera-rentals .homepage-blocks-content-right,
.block-bean-news .homepage-blocks-content-right,
.block-bean-specialty-equipment .homepage-blocks-content-left {
  height: 360px;
  width: 100%;
  @include breakpoint($narrow-screen) {
    width: 66%;
    height: 255px;
  }
  @include breakpoint($tablet-screen) {
    height: 306px;
  }
  @include breakpoint($standard-screen) {
    height: 392px;
  }
  @include breakpoint($wide-screen) {
    height: 574px;
  }
  .field-name-field-homepage-slot-image {
    height: 100%;
  }
}

.block-bean-camera-rentals .homepage-blocks-content-left,
.block-bean-news .homepage-blocks-content-left,
.block-bean-specialty-equipment .homepage-blocks-content-right {
  @include stripes('white');
  h2 {
    color: $primary;
  }
}

.block-bean-educational-events--2,
.block-bean-quote-tool,
.block-bean-quote-tool--2,
.block-bean-educational-events {
  @include stripes('green');
}

.block-bean-camera-rentals .homepage-blocks-content-left,
.block-bean-news .homepage-blocks-content-left,
.block-bean-specialty-equipment .homepage-blocks-content-right,
.block-bean-educational-events--2,
.block-bean-quote-tool,
.block-bean-quote-tool--2,
.block-bean-educational-events,
.view--homepage-block-featured-equipment--block .views-fieldset {
  &:before {
    bottom: 5px;
    right: 5px;
    width: calc(100% - 10px);
    height: 40px;
    z-index: 1;
    @include breakpoint($standard-screen) {
      height: 50px;
    }
  }
}

.block-bean-social-media,
.block-bean-social-media--2 {
  position: relative;
  border: none;
  @include breakpoint($standard-screen) {
    min-height: 402px;
  }
  .homepage-blocks-content-square {
    border: 1px solid $primary;
    height: auto;
  }
}

.homepage-blocks-social-links {
  width: 100%;
  @include breakpoint($standard-screen) {
    position: absolute;
    bottom: 0;
  }
  a {
    display: inline-block;
    margin-top: 3.1%;
    padding: 0.5em;
    width: 48.4%;
    color: $grey;
    font-size: 2em;
    text-align: center;
    text-decoration: none;
    border: 1px solid $primary;
    @include breakpoint($narrow-screen) {
      width: 49%;
      padding: 22px;
      margin-top: 16px;
    }
    @include breakpoint($standard-screen) {
      width: 47.5%;
      padding: 20px;
      font-size: 2.5em;
    }
    @include breakpoint(1166px) {
      padding: 25px;
    }
    &:hover {
      color: $white;
      background: $primary;
    }
    &:nth-child(odd) {
      float: left;
    }
    &:nth-child(even) {
      float: right;
    }
  }
}

.bean-homepage-blocks {
  .content {
    display: table;
    width: 100%;
    img {
      width: 100%;
      max-width: inherit;
    }
  }
}

a.homepage-block-link {
  @extend .btn-arrow;
  color: $primary;
  &:hover {
    color: $white;
  }
  &:after {
    width: 40px;
    height: 40px;
    font-size: 19px;
    @include breakpoint($standard-screen) {
      padding-top: 3px;
      font-size: 21px;
    }
  }
}

.homepage-blocks-content-right, .homepage-blocks-content-left {
  @include breakpoint($narrow-screen) {
    display: table-cell;
    vertical-align: top;
  }
}

.homepage-blocks-padding {
  padding: 2em 2em 5em;
  @include breakpoint($standard-screen) {
    padding: 3em;
  }
  .block-bean-social-media--2 & {
    padding: 2em;
  }
}

.homepage-blocks-content-right,
.homepage-blocks-content-left,
.homepage-blocks-content-square {
  position: relative;
  @include breakpoint($standard-screen) {
    height: 400px;
  }
}

.homepage-block-image {
  position: absolute;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.homepage-blocks-tablet {
  display: none;
  @include breakpoint($narrow-screen) {
    display: table-cell;
  }
}

.homepage-blocks-mobile-only {
  @include breakpoint($narrow-screen) {
    display: none;
  }
}

.view--homepage-block-featured-equipment--block {
  @extend .block-bean-homepage-blocks;
  width: 100%;
  .views-row {
    display: table;
    width: 100%;
  }
  // Mobile images
  .image-container-mobile {
    position: relative;
    height: 315px;
    @include breakpoint($small-screen) {
      height: 420px;
    }
    @include breakpoint($narrow-screen) {
      display: none;
    }
  }
  // Desktop Images
  .image-container {
    position: relative;
    display: none;
    @include breakpoint($narrow-screen) {
      display: inline-block;
      float:right;
      width: 60%;
      height: 324px;
    }
    @include breakpoint($tablet-screen) {
      height: 432px;
    }
    @include breakpoint($standard-screen) {
      width: 66%;
      height: 544px;
    }
    @include breakpoint($wide-screen) {
      height: 632px;
    }
  }
  .homepage-block-image {
    top: 0;
    position: relative;
  }
  .views-fieldset {
    box-sizing: border-box;
    @extend .homepage-blocks-padding;
    padding-bottom: 5em;
    @include breakpoint($narrow-screen) {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      width: 40%;
    }
    @include breakpoint($standard-screen) {
      width: 34%;
    }
    @include stripes('white');
  }
  .views-field-view-node a {
    @extend .homepage-block-link;
  }
  .views-field-nothing {
    margin-bottom: 1em;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 2px;
    @include breakpoint($tablet-screen) {
      font-size: 14px;
    }
  }
  .views-field-title {
    color: $primary;
  }
}
.block-bean-homepage-blocks.block-bean-news {
  display: block;
}
