.block-whites-location-session-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: $black;
  z-index: 20;
  overflow: auto;
}

.whites-location-session-overlay-hidden {
  display: none;
}

.whites-location-session-container {
  margin: 30px auto;
  padding: 0 13px;
  max-width: 800px;
  text-align: center;
  @include breakpoint($tablet-screen) {
    margin: 100px auto 20px;
  }
}

.whites-location-session-header {
  margin: 40px 30px;
  font-size: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.whites-location-session-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.whites-location-session-term {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin: 5px;
  padding: 40px 40px 80px;
  max-width: 370px;
  border: 1px solid $white;
  text-align: left;
  @include stripes('green');
  &:before {
    left: 5px;
    bottom: 5px;
    width: calc(100% - 10px);
    height: 50px;
  }
}

.whites-location-session-term-title {
  color: $primary;
}

.whites-location-session-link a {
  &:hover {
    color: $white;
  }
}

.whites-location-session-toggle-term {
  display: inline-block;
  vertical-align: middle;
}

.whites-location-session-toggle-content {
  text-align: center;
  @include breakpoint($narrow-screen) {
    text-align: left;
  }
}

.whites-location-session-toggle-link a {
  color: $grey-light;
  &.active-term {
    color: $primary;
  }
  &:hover {
    color: $primary;
  }
}

.block-whites-location-session-toggle-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  padding: 13px;
  width: 100%;
  background: transparentize($black, .15);
  z-index: 50;
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s linear .5s, opacity .5s ease;
}

.session-toggle-visible {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s;
}

.whites-location-session-overlay-content {
  margin: 50px auto 0;
  padding: 1em;
  max-width: 600px;
  font-family: $font-set-1;
  font-size: 1.3em;
  border: 1px solid $white;
  @include breakpoint($narrow-screen) {
    margin: 200px auto 0;
    padding: 3em;
  }
}

.whites-location-session-logo img {
  max-width: 40%
}

.whites-location-session-overlay-links button {
  font-size: 12px;
  width: 100%;
  margin-top: 10px;
  @include breakpoint($narrow-screen) {
    width: auto;
  }
}

#btn-whites-location-session-continue {
  @include breakpoint($narrow-screen) {
    float: right;
  }
}

.whites-location-session-overlay-alert {
  margin-bottom: 3em;
}

