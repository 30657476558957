.view-id-product_listing {
  .view-header {
    font-size: 14px;
    @include breakpoint($tablet-screen) {
      @include grid-span(8, 1);
      float: none;
      margin-bottom: 2em;
    }
  }
  .views-row {
    display: table;
    padding-bottom: 2em;
    margin-bottom: 2em;
    width: 100%;
    border-bottom: 1px solid $primary;
    @include breakpoint($tablet-screen) {
      min-height: 160px;
    }
    @include breakpoint($standard-screen) {
      min-height: 225px;
    }
  }
  .views-field-title a {
    @extend h2;
    display: inline-block;
    margin-bottom: 1em;
    color: $black;
  }
  .views-field-field-image {
    height: 100%;
    .field-content {
      position: relative;
      border: 1px solid $grey;
      height: 100%;
      text-align: center;
      @include breakpoint($narrow-screen) {
        display: inline-block;
        vertical-align: top;
        width: 250px;
        height: 188px;
      }
      @include breakpoint($tablet-screen) {
        width: 200px;
        height: 151px;
      }
      @include breakpoint($wide-screen) {
        width: 300px;
        height: 226px;
      }
    }
    img {
      display: block;
      margin: auto;
      width: 100%;
      // height: 65.6vw;
      object-fit: contain;
      font-family: 'object-fit: contain;';
      @include breakpoint($narrow-screen) {
        width: auto;
        height: 100%;
      }
    }
  }
  .views-fieldset {
    padding: 1em 0 0;
    @include breakpoint($narrow-screen) {
      display: table-cell;
      padding: 0 1em;
      width: 100%;
      vertical-align: top;
    }
    @include breakpoint($wide-screen) {
      padding: 0 2.3em;
    }
  }
  .views-field-add-to-cart-form, .views-field-view-node {
    display: inline-block;
    vertical-align: middle;
  }
  .views-field-view-node {
    margin-right: 2em;
    a:hover {
      color: $black;
    }
  }
  .views-field-field-body {
    margin-bottom: 2em;
  }
  .views-widget-filter-title {
    width: 100%;
  }
  .views-exposed-form label {
    @include element-invisible;
  }
}

.block-subpage-menus {
  + #block-views-product-listing-block-1,
  + #block-views-product-listing-block-2 {
    padding-top: 2em;
    border-top: 1px solid $black;
  }
}

.default-product-image {
  position: relative;
  width: 100%;
  height: 100%;
  background: $white;
  @include stripes('black');
  &:before {
    width: 100%;
    height: 100%;
    left: 0;
  }
  img {
    opacity: .5;
  }
}

#edit-add-more {
  margin-top: 1.5em;
  display: block;
  @include breakpoint($small-screen) {
    margin-left: .5em;
    display: inline;
    margin-top: 0;
  }

}
