.page-cart, .page-checkout,  {
  .section-content {
    color: $black;
    background: $white;
  }
}

input.cart-update-list {
  float: right;
}

input.delete-line-item,
input.cart-update-list,
input.checkout-cancel,
input.checkout-back {
  @include button-hollow('black');
}

.view-commerce-cart-form,
#commerce-checkout-form-checkout,
#commerce-checkout-form-review {
  @include breakpoint($tablet-screen) {
    width: 80%;
  }
  fieldset {
    margin: 2em 0;
    @include breakpoint($narrow-screen) {
      padding: 1.5em;
    }
    &.checkout-buttons {
      padding: 0;
    }
    fieldset {
      .fieldset-legend {
        color: $black;
      }
    }
  }
  .customer_profile_billing {
    input {
      width: 100%;
      @include breakpoint($narrow-screen) {
        width: auto;
      }
    }
  }
  th {
    font-family: $font-set-1;
    font-weight: lighter;
  }
  th, td {
    padding: 10px 20px;
    &:not(:first-child) {
      text-align: center;
    }
  }
  td {
    width: 10%;
    border-bottom: 1px solid #d5d5d5;
  }
  tr {
    &:last-child td {
      border-color: $black;
    }
    &.even td {
      background: #F5F5F5;
    }
  }
  .views-field-line-item-title {
    width: 100%;
  }
  caption {
    @extend h2;
    text-transform: uppercase;
  }
  .fieldset-legend {
    padding: 0 10px;
    color: $primary;
    @extend h2;
  }
  .views-table {
    margin-bottom: 2em;
    &:last-child {
      margin-bottom: 0;
    }
  }
  label {
    margin-bottom: 5px;
    font-weight: normal;
  }
  select {
    min-width: 70px;
    border-color: $black;
  }
  .cart_contents, .checkout-review {
    margin-top: 0;
  }
}

.group-usage {
  .field-name-field-expected-wrap-date,
  .field-name-field-shooting-dates {
    @include breakpoint($standard-screen) {
      display: inline-block;
      width: 50%;
    }
  }
  .field-name-field-expected-wrap-date fieldset {
    @include breakpoint($standard-screen) {
      border-right: 0;
    }
  }
  .field-name-field-shooting-dates fieldset {
    @include breakpoint($standard-screen) {
      border-left: 0;
    }
  }
}

.checkout-help {
  @extend h3;
}

.checkout-buttons {
  padding: 0;
  border: none;
}

.checkout-review {
  margin-bottom: 1em;
  .pane-title {
    font-weight: bold;
    font-size: em(14px);
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  .pane-data-full {
    padding: 0;
  }
  .field-label {
    margin-bottom: .25em;
    color: #808080;
    font-size: em(14px);
    font-weight: normal;
  }
  .field {
    margin-bottom: 1em;
    padding-bottom: .5em;
    border-bottom: 1px solid lightgrey;
    &:last-child {
      margin-bottom: auto;
      padding-bottom: initial;
      border-bottom: initial;
    }
  }
  .field-item {
    word-break: break-word;
  }
  table:first-child caption {
    margin-top: auto;
  }
}

.button-operator {
  display: none;
}

.line-item-total {
  margin: 1em 0;
}

.description {
  margin-top: 5px;
}

.checkout-continue {
  margin-right: 10px;
}

.checkout-processing {
  display: none;
}

.quote-note {
  width: 80%;
}

