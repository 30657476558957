.node-type-product-page,
.node-type-expendable-page,
.node-type-department-category,
.node-type-expendable-categories {
  .section-content {
    color: $black;
    background: white;
    .node a {
      color: $black;
      &:hover {
        color: $primary;
      }
    }
  }
  .tabs a {
    @include button-hollow('black');
  }
}

.node-product-page--default {
  @include add-grid(7);
  .view-id-resource_library {
    margin-bottom: 2em;
    @include breakpoint($narrow-screen) {
      @include float-span(5, 'last');
    }
  }
  .field-name-field-gallery-images {
    @include breakpoint(max-width $narrow-screen) {
      @include layout(4) {
        .field-item {
          margin-bottom: 2.85714%;
          @include add-gutter(1/8);
          @include float-span(2);
          &.odd {
            @include float-span(2, 'last');
          }
        }
      }
    }
    @include breakpoint($narrow-screen) {
      @include float-span(5, 'last');
      @include layout(4) {
        @include add-gutter(.5em);
        .field-item {
          @include float-span(1);
        }
      }
    }
    img {
      width: 100%;
      border: 1px solid $black;
    }
  }
}

.field-name-field-new-product, .views-field-field-new-product {
  @extend h2;
  color: $primary;
  margin-bottom: 0;
}

.commerce-add-to-cart {
  .form-item-quantity {
    display: inline-block;
  }
  label, input {
    display: inline-block;
    margin-right: .5em;
    text-transform: uppercase;
    font-size: em(12px);
    border-color: $black;
    letter-spacing: 2px;
  }
  input[type=submit]:hover {
    color: $white;
    background: $primary;
  }
}

.form-text[name=quantity] {
  text-align: center;
}

.group-image {
  @include breakpoint($narrow-screen) {
    @include float-span(2);
  }
  a {
    display: block;
    margin-bottom: 2em;
    text-align: center;
  }
  img {
    max-width: 100%;
    border: 1px solid $black;
  }
}

.group-image + .group-product-description {
  @include breakpoint($narrow-screen) {
    @include float-span(5, 'last');
  }
}

.group-product-description {
  margin: 0 1em;
  @include breakpoint($narrow-screen) {
    margin: 0 0 2em;
  }
  .commerce-product-title {
    margin-bottom: 2em;
  }
  .field-type-commerce-price {
    margin-bottom: 1em;
  }
}

.group-product-extra {
  position: relative;
  padding-top: 2em;
  margin-top: 2em;
  border-top: 1px solid $primary;
  clear: both;
  @include clearfix;
  @include breakpoint($narrow-screen) {
    @include stripes('black');
    &:before {
      width: 27%;
      height: calc(100% - 2em);
    }
  }
}
