.homepage-video {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  z-index: -999;
  width: 100%;
  @media (min-aspect-ratio: 16/9) {
    height: 300%;
  }
  @media (max-aspect-ratio: 16/9) {
    width: 300%;
    left: -100%;
  }
  @include breakpoint($standard-screen) {
    display: block;
  }
}

.btn, .btn-primary {
  padding: 9px 30px 7px;
  @include button('white', 'black');
}

.btn-secondary {
  padding: 9px 30px 7px;
  @include button-hollow('white');
}

.btn-arrow {
  &:after {
    content: "\e87a";
    display: inline-block;
    margin-top: 1em;
    padding-top: 5px;
    width: 45px;
    height: 45px;
    font-size: 24px;
    font-family: 'Linearicons-Free';
    border: 2px solid;
    border-radius: 50%;
    text-align: center;
  }
}

.btn-down-arrow {
  display: inline-block;
  width: 70px;
  height: 70px;
  transition: none;
  background-image: url(/sites/all/themes/de_theme/dist/img/down-caret-white.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  &:hover {
    background-image: url(/sites/all/themes/de_theme/dist/img/down-caret-green.svg);
  }
}

.views-field-view-node {
  display: inline-block;
  a {
    display: inline-block;
    @extend .btn-arrow;
    &:after {
      margin: 0;
      font-size: 22px;
    }
  }
}

.pager {
  margin: 1em;
  padding: 0;
  text-align: center;
  li {
    display: inline-block;
    padding: .5em;
    font-size: em(12px);
    letter-spacing: 1px;
    list-style-type: none;
    text-transform: uppercase;
  }
  a {
    display: inline-block;
    font-weight: lighter;
  }
  .pager-current {
    font-weight: bold;
  }
}

.block-search {
  padding: 1.5em;
  border-bottom: 1px solid $grey;
  .block-content {
    margin: auto;
    max-width: $max-width;
  }
  .form-item-search-block-form, input[type=search] {
    padding-right: 10px;
    width: 100%;
    border-color: $white;
  }
  .form-item-search-block-form, .form-actions {
    display: table-cell;
  }
}

#user-login {
  .form-submit {
    @include button('white', 'black');
  }
}
